import * as React from 'react'
import Search from '../components/search'
import {useStaticQuery, graphql} from 'gatsby'

function BlogScreen() {
  const result = useStaticQuery(
    // frontmatter: {published: {ne: false}, unlisted: {ne: false}}
    // graphql`
    //   query {
    //     blogposts: allMdx(
    //       sort: {fields: frontmatter___date, order: DESC}
    //       filter: {
    //         fileAbsolutePath: {regex: "//content/blog//"}
    //       }
    //     ) {
    //       edges {
    //         node {
    //           fields {
    //             id
    //             slug
    //             productionUrl
    //             title
    //             categories
    //             keywords
    //             description: plainTextDescription
    //             bannerUrl
    //           }
    //           excerpt(pruneLength: 190)
    //         }
    //       }
    //     }
    //   }
    // `,
    graphql`
      query {
        blogposts: allMdx(
          sort: {fields: frontmatter___date, order: DESC}
          filter: {
            fileAbsolutePath: {regex: "//content/blog//"}
            frontmatter: {published: {eq: true}}
          }
        ) {
          edges {
            node {
              fields {
                id
                slug
                title
                description: plainTextDescription
              }
              excerpt(pruneLength: 190)
            }
          }
        }
      }
    `,
  )
  return <Search blogposts={result.blogposts} />
}

export default BlogScreen

/*

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export default function BlogPostsPage({data}) {
  return (
    <Layout>
      <h1>Meine Artikel</h1>

      {data.allFile.edges.map((blog) => (
        <h2>
          {blog.createdAt} - {blog.title}
        </h2>
      ))}
    </Layout>
  )
}

export const postsQuery = graphql`
query BlogPostsQuery{
  allFile(filter: { sourceInstanceName: { eq: "blog-posts" } }) {
      edges {
        node {
          relativePath
          prettySize
          extension
          birthTime
        }
      }
  }
}
`

// export const postsQuery = graphql`
//   query BlogPostsQuery {
    // allMarkdownRemark(
    //   sort: { order: DESC, fields: [frontmatter___date] }
    //   limit: 1000
    //    filter: {frontmatter: {slug: {regex: "/^\/blog\//"}}}
    // ) {
    //   edges {
//         node {
//           frontmatter {
//             slug,
//             title
//           }
//         }
//       }
//     }
//   }
// `

*/