import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

const buttonBaseStyles = tw`py-3 px-8 font-sans rounded border border-primary`
const extraStyles = {
  true: tw`bg-primary text-white hover:bg-opacity-90`,
  false: tw`bg-white text-primary hover:bg-gray-50`,
}

const BasicButton = styled.button( ({ primary }) => [buttonBaseStyles, extraStyles[primary]] )

const Button = ({ link, text, primary = false }) => {
  return (
    <a href={link}>
      <BasicButton primary={primary}>
        {text}
      </BasicButton>
    </a>
  )
}

export default Button

Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  isPrimary: PropTypes.bool,
}

Button.defaultProps = {
  link: null,
  text: null,
  isPrimary: false,
}
