import tw from "twin.macro"

const Textmarker = tw.span`
  px-1
  font-normal
  bg-fuchsia-400
  hover:bg-fuchsia-500
  `

export default Textmarker
