import React from "react"

const JahreAlsFreiberufler = () => {

    const atSaw = {
        years: 8,
        months: 10,
    }
    const monthsAtSaw = atSaw.years * 12 + atSaw.months
    const startAsHS = {
        year: 2020,
        month: 9,
    }
    const year = new Date().getFullYear()
    const month = 1 + new Date().getMonth()
    const monthsAsHS = (year - startAsHS.year) * 12 + (month - startAsHS.month)

    const monthsTotal = monthsAtSaw + monthsAsHS

    const displayMonths = monthsTotal % 12
    const displayYears = Math.floor(monthsTotal / 12)
    const jaf = displayYears + (displayMonths >= 6 ? ',5' : '')

    return (<>{jaf}</>)
}

export default JahreAlsFreiberufler
